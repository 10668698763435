/* eslint-disable react/forbid-prop-types */
import React from 'react'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'

import DataGraph from '@e2grnd/ui-core/dist/containers/DataGraph'
import { E2GSelectFieldOption } from '@e2grnd/ui-core/dist/containers/E2GSelectField'
import E2GSelectFieldIndexer from '@e2grnd/ui-core/dist/containers/E2GSelectFieldIndexer'

import { assignGridSize, getPofGraphs2, validateNextTaDate, validateTurnaroundFrequency } from '../lib/getResultCases.js'

const POF = ({ results, nextTaDate, turnaroundFrequency }) => {
  const items = []
  const endIndexObject = {}
  let graphData
  let graphKeys
  let firstKey
  let cmlAnalysisIds

  if(typeof results.thicknessProjections !== 'undefined') {
    const newNextTaDate = validateNextTaDate(nextTaDate)
    const newTurnaroundFrequency = validateTurnaroundFrequency(turnaroundFrequency)
    const inputDates = [results.analysisDate[0], newNextTaDate, newTurnaroundFrequency]
    const jsonObject = JSON.parse(results.thicknessProjections[0])
    cmlAnalysisIds = Object.keys(jsonObject)
    const cmlCount = cmlAnalysisIds.length
    const gridSize = assignGridSize(cmlCount)
    const numPlots = Math.ceil(cmlCount / gridSize)
    for(let i = 0; i < numPlots; i += 1) {
      if(i < numPlots - 1) {
        const startIndex = i * gridSize
        const endIndex = (i + 1) * gridSize - 1
        const startCml = jsonObject[startIndex + 1].cmlId
        const endCml = jsonObject[endIndex + 1].cmlId
        const selectLabel = `CML ${startCml}[${startIndex + 1}]-${endCml}[${endIndex + 1}]`
        items.push(<E2GSelectFieldOption label={selectLabel} value={startIndex + 1} key={startIndex + 1} />)
        endIndexObject[startIndex + 1] = endIndex + 1
      } else {
        const updatedCmlCount = cmlCount - gridSize * i
        const newGridSize = assignGridSize(updatedCmlCount)

        const numPlotsNew = Math.ceil(updatedCmlCount / newGridSize)
        if(numPlotsNew > 0) {
          for(let j = 0; j < numPlotsNew; j += 1) {
            if(j < numPlots - 1) {
              const startIndex = i * gridSize + j * newGridSize
              const endIndex = i * gridSize + (j + 1) * newGridSize - 1
              const startCml = jsonObject[startIndex + 1].cmlId
              const endCml = jsonObject[endIndex + 1].cmlId
              const selectLabel = `CML ${startCml}[${startIndex + 1}]-${endCml}[${endIndex + 1}]`
              items.push(<E2GSelectFieldOption label={selectLabel} value={startIndex + 1} key={startIndex + 1} />)
              endIndexObject[startIndex + 1] = endIndex + 1
            } else {
              const startIndex = i * gridSize + j * newGridSize
              const endIndex = cmlCount - 1
              const startCml = jsonObject[startIndex + 1].cmlId
              const endCml = jsonObject[endIndex + 1].cmlId
              const selectLabel = `CML ${startCml}[${startIndex + 1}]-${endCml}[${endIndex + 1}]`
              items.push(<E2GSelectFieldOption label={selectLabel} value={startIndex + 1} key={startIndex + 1} />)
              endIndexObject[startIndex + 1] = endIndex + 1
            }
          }
        } else {
          const startIndex = i * gridSize
          const endIndex = cmlCount - 1
          const startCml = jsonObject[startIndex + 1].cmlId
          const endCml = jsonObject[endIndex + 1].cmlId
          const selectLabel = `CML ${startCml}[${startIndex + 1}]-${endCml}[${endIndex + 1}]`
          items.push(<E2GSelectFieldOption label={selectLabel} value={startIndex + 1} key={startIndex + 1} />)
          endIndexObject[startIndex + 1] = endIndex + 1
        }
      }
    }

    const endIndexIn = endIndexObject[results.pofKeys ? results.pofKeys[0] : cmlAnalysisIds[0]]
    graphData = getPofGraphs2(results.thicknessProjections, results.pofKeys ? results.pofKeys[0] : cmlAnalysisIds[0], endIndexIn, inputDates)
    graphKeys = Object.keys(graphData)
    firstKey = graphKeys[0]
  } else {
    console.log("Results:")
    console.log(results)
  }

  return (
    <div className="cf w-100">
      {results.thicknessProjections
        ? (
          <div className="fl w-100">
            <E2GSelectFieldIndexer name="pofKeys" value={results.pofKeys ? results.pofKeys[0] : cmlAnalysisIds[0]} label="Select CML(s) To Plot">
              {items}
            </E2GSelectFieldIndexer>
            <div>
              <DataGraph plotClass="data-graph-85" data={graphData[firstKey].traces} layout={graphData[firstKey].layout} />
            </div>
          </div>
        )
        : <p>No Graphs to Display</p>}
    </div>
  )
}

POF.propTypes = {
  results: PropTypes.object,
  nextTaDate: PropTypes.string,
  turnaroundFrequency: PropTypes.number,
}

const mapStateToProps = state => ({
  results: state.workflow.response ? state.workflow.response.results : undefined,
  nextTaDate: state.workflow.fields.nextTaDate,
  turnaroundFrequency: state.workflow.fields.turnaroundFrequency,
})

export default connect(mapStateToProps, null)(POF)
