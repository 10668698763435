import React, { Component } from 'react'
import { connect } from 'react-redux'
import Button from '@mui/material/Button'
import Stack from '@mui/material/Stack'
// import isEqual from 'lodash/isEqual';
import find from 'lodash/find'
import PropTypes from 'prop-types'

import DataGraph from '@e2grnd/ui-core/dist/containers/DataGraph'
import DataTableDisplay from '@e2grnd/ui-core/dist/containers/DataTableDisplay'
import StartOver from '@e2grnd/ui-core/dist/containers/StartOver'
import { updateResponseField } from '@e2grnd/ui-core/dist/contexts/redux/actions'
import withConfig from '@e2grnd/ui-core/dist/contexts/withConfig'

import { getMetalLossProjectionGraphs2, getPlotInputs, getPofGraphs2, getResidual, getRiskMatrixData, getThicknessProjectionGraphs2 } from '../lib/getResultCases.js'
import { stringToBlob } from '../lib/stringToBlob.ts'

class Step4 extends Component {
  static propTypes = {
    results: PropTypes.object,
    error: PropTypes.shape({
      valueString: PropTypes.string,
      valuesInteger: PropTypes.number,
      valuesFloat: PropTypes.number,
      name: PropTypes.string,
      valueType: PropTypes.number,
    }),
    config: PropTypes.object,
    summaryData: PropTypes.object,
    summaryDataR: PropTypes.object,
    thicknessPlotsReportFlag: PropTypes.bool,
    metalLossPlotsReportFlag: PropTypes.bool,
    residualPlotsReportFlag: PropTypes.bool,
    pofPlotsReportFlag: PropTypes.bool,
    nextTaDate: PropTypes.string,
    turnaroundFrequency: PropTypes.number,
    dispatch: PropTypes.func,
    circuit: PropTypes.string,
    problemType: PropTypes.string,
    htmlOutFileDummy: PropTypes.string,
    riskMatrixJson: PropTypes.string,
    rmData: PropTypes.object,
  }

  componentDidUpdate() {
    const { results, residualPlotsReportFlag, pofPlotsReportFlag, thicknessPlotsReportFlag, metalLossPlotsReportFlag, nextTaDate, turnaroundFrequency, dispatch, problemType, riskMatrixJson } = this.props

    // let tempGraphObject = ''
    // let tempGraphObject2 = ''
    // let tempGraphObject3 = ''
    // let tempGraphObject4 = ''
    // if(typeof thicknessPlotsReportFlag !== 'undefined' && thicknessPlotsReportFlag) {
    //   if(typeof results.thicknessProjections !== 'undefined') {
    //     const thicknessPlotInputs = getPlotInputs(results.thicknessProjections[0], nextTaDate, turnaroundFrequency, results.analysisDate[0])
    //     const thicknessGraphs = []
    //     thicknessPlotInputs.forEach((thickIn) => {
    //       const graphData = getThicknessProjectionGraphs2(results.thicknessProjections, thickIn[0], thickIn[1], thickIn[2])
    //       const graphKeys = Object.keys(graphData)
    //       const graphKey = graphKeys[0]
    //       thicknessGraphs.push(graphData[graphKey])
    //     })
    //     tempGraphObject = JSON.stringify(thicknessGraphs, null, 2)
    //     if(typeof results.thicknessPlotsGraphObject === 'undefined') {
    //       dispatch(updateResponseField({
    //         name: 'thicknessPlotsGraphObject',
    //         value: tempGraphObject,
    //       }))
    //     }
    //   }
    // }
    // if(typeof metalLossPlotsReportFlag !== 'undefined' && metalLossPlotsReportFlag) {
    //   if(typeof results.metalLossProjections !== 'undefined') {
    //     const metalLossPlotInputs = getPlotInputs(results.metalLossProjections[0], nextTaDate, turnaroundFrequency, results.analysisDate[0])
    //     const metalLossGraphs = []
    //     metalLossPlotInputs.forEach((metalLossIn) => {
    //       const graphData = getMetalLossProjectionGraphs2(results.metalLossProjections, metalLossIn[0], metalLossIn[1], metalLossIn[2])
    //       const graphKeys = Object.keys(graphData)
    //       const graphKey = graphKeys[0]
    //       metalLossGraphs.push(graphData[graphKey])
    //     })
    //     tempGraphObject2 = JSON.stringify(metalLossGraphs, null, 2)
    //     if(typeof results.metalLossPlotsGraphObject === 'undefined') {
    //       dispatch(updateResponseField({
    //         name: 'metalLossPlotsGraphObject',
    //         value: tempGraphObject2,
    //       }))
    //     }
    //   }
    // }
    // if(typeof pofPlotsReportFlag !== 'undefined' && pofPlotsReportFlag) {
    //   if(typeof results.metalLossProjections !== 'undefined') {
    //     const pofPlotInputs = getPlotInputs(results.thicknessProjections[0], nextTaDate, turnaroundFrequency, results.analysisDate[0])
    //     const pofGraphs = []
    //     pofPlotInputs.forEach((metalLossIn) => {
    //       const graphData = getPofGraphs2(results.thicknessProjections, metalLossIn[0], metalLossIn[1], metalLossIn[2])
    //       const graphKeys = Object.keys(graphData)
    //       const graphKey = graphKeys[0]
    //       pofGraphs.push(graphData[graphKey])
    //     })
    //     tempGraphObject3 = JSON.stringify(pofGraphs, null, 2)
    //     if(typeof results.pofPlotsGraphObject === 'undefined') {
    //       dispatch(updateResponseField({
    //         name: 'pofPlotsGraphObject',
    //         value: tempGraphObject3,
    //       }))
    //     }
    //   }
    // }
    // if(typeof residualPlotsReportFlag !== 'undefined' && residualPlotsReportFlag) {
    //   if(typeof results.residualMedians !== 'undefined') {
    //     const residualGraphs = []
    //     const medians = results.residualMedians
    //     const lowerErrors = results.residualLowerBounds
    //     const upperErrors = results.residualUpperBounds
    //     const graphData = getResidual(medians, lowerErrors, upperErrors, problemType)
    //     residualGraphs.push(graphData)
    //     tempGraphObject4 = JSON.stringify(residualGraphs, null, 2)
    //     if(typeof results.residualPlotsGraphObject === 'undefined') {
    //       dispatch(updateResponseField({
    //         name: 'residualPlotsGraphObject',
    //         value: tempGraphObject4,
    //       }))
    //     }
    //   }
    // }
  }

  render() {
    const {
      results,
      error,
      config,
      summaryData,
      summaryDataR,
      circuit,
      problemType,
      htmlOutFileDummy,
      riskMatrixJson,
      rmData,
    } = this.props
    if(typeof results.summaryDataRowHeaders !== 'undefined') {
      const tableData = find(config.dataTables, ['name', 'summaryDataR'])
      tableData.keywords[0].rowHeaders = true
      tableData.options.rowHeaders = results.summaryDataRowHeaders
    }
    let modelCriteriaString = 'There are no WAIC and LOO deviance estimates available for this analysis.\n A gr_psrf = -1 indicates parameter converged.'

    if(typeof results.waicData !== 'undefined') {
      const waicData = results.waicData
      const looData = results.looData
      // eslint-disable-next-line no-unused-vars
      modelCriteriaString = `The WAIC and LOO  estimates are ${waicData[0].toFixed(4)} and ${looData[0].toFixed(4)}, SEs are ${waicData[1].toFixed(4)} and ${looData[1].toFixed(4)}, and p values are ${waicData[2].toFixed(4)} and ${looData[2].toFixed(4)} respectively.`
    }

    let rmGraphData

    if(problemType === 'R') {
      console.log("... rmGraphData assigned.")
      rmGraphData = getRiskMatrixData(riskMatrixJson)
    }

    return (
      <div className="Step4">
        {results.summaryDataRowHeaders
          ? (
            <div>
              {problemType === 'R'
                ? (
                  <div className="fl w-100 pa3">
                    <Stack direction="row" spacing={2}>
                      <Button
                        variant="outlined"
                        color="secondary"
                        onClick={() => { stringToBlob(results.htmlOutFile, circuit + '.html') }}
                      >
                        {circuit} Report Results
                      </Button>
                      <Button
                        variant="outlined"
                        color="secondary"
                        onClick={() => { stringToBlob(results.jsonOutputData, circuit + '_CR.json') }}
                      >
                        {circuit} Corrosion Rate JSON Results
                      </Button>
                    </Stack>

                    <div className = "f1 w-100" style={{ border: '2px solid black', padding: '10px' }}>
                      <DataGraph plotClass="data-graph-85" data={rmGraphData.traces} layout={rmGraphData.layout} />
                    </div>
                    <div>
                      <DataTableDisplay id="summaryDataR" table={rmData} colHeaders={{ USCustomary: ['Year', 'Circ', 'Iso/CML', 'Cons.', 'POF', 'Risk', 'Risk Target'], Metric: ['Year', 'Circ', 'Iso/CML', 'Cons.', 'POF', 'Risk', 'Risk Target'] }} containerHeight="300px" />
                    </div>

                  </div>
                )
                : ''}
              {/* {problemType === 'R' ? (
                <p>
                  <a href="/pdf/AbaqusFEAModelDataMapperUserManual.pdf" target="_blank" rel="noreferrer noopener">[HTML REPORT]</a>
                </p>
              ) : ''} */}
              {problemType !== 'R'
                ? (
                  <div className="fl w-100">
                    <p>{modelCriteriaString}</p>
                    <DataTableDisplay id="summaryData" table={summaryData} colHeaders={{ USCustomary: ['Mean', 'SD', 'HDI 3%', 'HDI 97%', 'MCSE Mean', 'MCSE SD', 'ESS Bulk', 'ESS Tail', 'R Hat'], Metric: ['Mean', 'SD', 'HDI 3%', 'HDI 97%', 'MCSE Mean', 'MCSE SD', 'ESS Bulk', 'ESS Tail', 'R Hat'] }} containerHeight="300px" />
                  </div>
                )
                : ''}
            </div>
          )
          : ''}
        <br />
        <StartOver />
      </div>
    )
  }
}

const mapStateToProps = state => ({
  summaryData: state.workflow.response.results.dataTables ? state.workflow.response.results.dataTables.summaryData : undefined,
  summaryDataR: state.workflow.response.results.dataTables ? state.workflow.response.results.dataTables.summaryDataR : undefined,
  results: state.workflow.response ? state.workflow.response.results : undefined,
  error: state.workflow.response ? state.workflow.response.error : undefined,
  thicknessPlotsReportFlag: state.workflow.fields.thicknessPlotsReportFlag,
  metalLossPlotsReportFlag: state.workflow.fields.metalLossPlotsReportFlag,
  residualPlotsReportFlag: state.workflow.fields.residualPlotsReportFlag,
  pofPlotsReportFlag: state.workflow.fields.pofPlotsReportFlag,
  nextTaDate: state.workflow.fields.nextTaDate,
  problemType: state.workflow.fields.problemType,
  turnaroundFrequency: state.workflow.fields.turnaroundFrequency,
  circuit: state.workflow.fields.circuit,
  htmlOutFileDummy: state.workflow.fields.htmlOutFileDummy,
  riskMatrixJson: state.workflow.response ? state.workflow.response.results.riskMatrixJson : undefined,
  rmData: state.workflow.response.results.dataTables ? state.workflow.response.results.dataTables.rmData : undefined,
})

export default connect(mapStateToProps, null)(
  withConfig(Step4)
)
